import React, { useEffect, useState } from "react";
import { Row, Col, Typography, Button } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import html2canvas from "html2canvas";
import { saveAs } from "file-saver";
import certificado from "../../assets/images/22.png";

const { Title, Paragraph } = Typography;

const Certificate = ({ state, course }) => {
  const [courseInfo, setCourseInfo] = useState(null);
  const [uuid, setUuid] = useState(null);

  const downloadCertificate = () => {
    const divToDownload = document.getElementById("certificate");
    html2canvas(divToDownload).then((canvas) => {
      canvas.toBlob((blob) => {
        saveAs(blob, "certificate.png");
      });
    });
  };

  const validateData = () => {
    const course_id = course.id;
    const approved_courses = state.profile.course_approved;

    approved_courses.map((approved) => {
      if (approved.course.id === course_id) {
        setUuid(approved.uuid);
        setCourseInfo(approved);
      }
    });
  };

  useEffect(() => {
    validateData();
  }, []);

  return (
    <>
      <Row justify={"center"}>
        <Col>
          <Button
            icon={<DownloadOutlined />}
            style={styles.downloadCertificate}
            onClick={downloadCertificate}
          >
            Descargar certificado
          </Button>
        </Col>
      </Row>
      <Row id="certificate" style={styles.container} justify="center">
        <Col
          span={24}
          style={{
            marginTop: window.innerWidth > 900 ? "-60px" : "20px",
            position: "absolute",
          }}
        >
          <Title level={1} style={styles.titleName}>
            CERTIFICADO
          </Title>
        </Col>
        <Col
          span={24}
          style={{
            marginTop: window.innerWidth > 900 ? "220px" : "250px",
            position: "absolute",
          }}
        >
          <Title level={1} style={{ color: "#6d458d" }}>
            {state.user.first_name.toUpperCase()}{" "}
            {state.user.last_name.toUpperCase()}
          </Title>
        </Col>
        <Col span={24} style={{ marginTop: "300px", position: "absolute" }}>
          <Paragraph style={styles.pCongratulations}>
            {" "}
            A aprobado correctamente el curso;{" "}
          </Paragraph>
        </Col>
        <Col span={24} style={{ marginTop: "340px", position: "absolute" }}>
          <Title
            level={window.innerwidth > 900 ? 1 : 3}
            style={styles.titleName}
          >
            {course.name}
          </Title>
        </Col>
      </Row>
    </>
  );
};

const styles = {
  downloadCertificate: {
    margin: "5px",
  },
  pCongratulationsD: {
    textAlign: "center",
    color: "#6d458d",
    fontSize: "15px",
    marginTop: "5px",
    fontWeight: "400",
  },

  pCongratulations: {
    textAlign: "center",
    color: "#6d458d",
    fontSize: "20px",
    fontWeight: "400",
  },
  titleName: {
    color: "#6d458d",
    textAlign: "center",
  },
  titleName2: {
    color: "#6d458d",
    textAlign: "center",
    marginBottom: "-10px",
    fontSize: "18px",
  },

  container: {
    background: `url(${certificado})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    padding: "8%",
    marginTop: "10px",
    borderRadius: "10px",
    height: "80%",
    width: window.innerWidth > 900 ? "1200px" : "450px",
  },
};

export default Certificate;
